import { useEffect } from 'react'
import { useAnalyticsSimplified } from '@scentregroup/analytics'
import { IUser } from '../types/user'

interface UserDataProfileTrackerProps {
  user: Pick<IUser, 'primaryCentre'> | undefined
  loading: boolean
}

export const UserDataProfileTracker: React.FC<UserDataProfileTrackerProps> = ({
  user,
  loading,
}) => {
  const analytics = useAnalyticsSimplified()
  const defaultValue = user ? 'no-preferred-centre' : undefined
  const preferredCentre = user?.primaryCentre?.slug ?? defaultValue
  useEffect(() => {
    if (!loading) {
      analytics.updateProfile({ preferredCentre })
    }
  }, [loading, preferredCentre, analytics])
  return null
}
