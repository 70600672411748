import { useRouter } from 'next/router'
import React, {
  useCallback,
  useMemo,
  useState,
  createContext,
  ReactNode,
  useEffect,
  useContext,
} from 'react'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@hub/modal'

import { Box } from '@hub/box'
import { Button } from '@hub/button'
import { IDialog } from '../types/dialog'

type DialogContextProviderValue = {
  openDialog: (dialog: IDialog) => void
  closeDialog: () => void
}

export const DialogContext = createContext({} as DialogContextProviderValue)

type Props = {
  children: ReactNode
}

export const DialogProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const [dialog, setDialog] = useState<IDialog | null>(null)
  const router = useRouter()

  const openDialog = useCallback((dialog: IDialog) => setDialog(dialog), [])

  const closeDialog = useCallback(() => setDialog(null), [])

  const handleCloseDialog = useCallback(() => {
    closeDialog()
    dialog?.onClose?.()
  }, [dialog, closeDialog])

  const action = useMemo(
    () => ({
      label: dialog?.action.label || 'Confirm',
      onClick: () => {
        closeDialog()
        dialog?.action.onClick()
      },
    }),
    [dialog, closeDialog]
  )

  useEffect(() => {
    const handleRouteChange = (): void => closeDialog()
    router.events.on('routeChangeComplete', handleRouteChange)

    // unsubscribe when unmounted
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router, closeDialog])

  const providerValue = useMemo(
    () => ({ openDialog, closeDialog }),
    [openDialog, closeDialog]
  )

  return (
    <DialogContext.Provider value={providerValue}>
      {dialog && (
        <Modal
          isOpen={true}
          onClose={handleCloseDialog}
          heading={dialog.heading}
          action={action}
          dataTestId={dialog.dataTestId}
          closeOnOverlayClick={dialog.closeOnOverlayClick}
          sx={dialog.sx}
          as={dialog.as}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{dialog.heading}</ModalHeader>
            <ModalCloseButton />
            <Box
              sx={{ borderBottom: '1px solid', borderBottomColor: 'ghost' }}
            />

            <ModalBody>{dialog.message}</ModalBody>
            <ModalFooter>
              <Button
                colorScheme={'licorice'}
                variant={'solid'}
                size={'md'}
                textAlign={'center'}
                type={'button'}
                width={'100%'}
                onClick={() => {
                  action.onClick()
                }}
              >
                {action.label}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {children}
    </DialogContext.Provider>
  )
}

export default (): DialogContextProviderValue => useContext(DialogContext)
