import { mapValues, get } from 'lodash'
import {
  WithCSSVar,
  useBreakpointValue, // no way to avoid this here
  useMediaQuery,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {
  HubResponsiveArray,
  useToken,
  DEFAULT_BREAKPOINT,
  HubResponsiveValue,
} from '../'

// TODO: what on earth is the actual type here
export const mapRules = (
  obj: Record<string, any>,
  mapper: (value: any, obj: Record<string, any>, path: string[]) => any,
  path: string[] = []
): Record<string, any> =>
  mapValues(obj, (value, key) => {
    if (value == null) {
      return value
    }
    // For media queries we recurse
    if (typeof value === 'object') {
      return mapRules(value, mapper, [...path, key])
    }
    return mapper(value, obj, [...path, key])
  })

export function useCssValueFromResponsiveProp<T extends string | number>(
  prop: HubResponsiveArray<T>,
  themeKey: string,
  defaultBreakpoint: string = DEFAULT_BREAKPOINT
): T | undefined {
  const propArray = Array.isArray(prop) ? prop : [prop]
  const propForBreakpoint: T | undefined = useBreakpointValue<T>(
    propArray as T[],
    defaultBreakpoint
  )

  const [cssValue] = useToken(
    themeKey,
    propForBreakpoint ? [propForBreakpoint] : []
  )

  return cssValue
}

type Get = (obj: Readonly<object>, path: string | number, fallback?: any) => any

const cache = new WeakMap()

const memoizedGet: Get = (obj, path, fallback) => {
  if (typeof obj === 'undefined') {
    return get(obj, path, fallback)
  }

  if (!cache.has(obj)) {
    cache.set(obj, new Map())
  }

  const map = cache.get(obj)

  if (map.has(path)) {
    return map.get(path)
  }

  const value = get(obj, path, fallback)

  map.set(path, value)

  return value
}

type Dict<T = any> = Record<string, T>

// Necessary until this is implemented in ChakraUI.
// More details: https://github.com/chakra-ui/chakra-ui/issues/4055
export function getToken<T extends string | number, U extends object = Dict>(
  theme: WithCSSVar<U>,
  scale: string,
  token: T | T[],
  fallback?: T | T[]
): T | T[] {
  if (Array.isArray(token)) {
    let fallbackArr: T[] = []
    if (fallback) {
      fallbackArr = Array.isArray(fallback) ? fallback : [fallback]
    }

    return token.map((token, index) => {
      const path = `${scale}.${token}`
      return memoizedGet(theme, path, fallbackArr[index] ?? token)
    })
  }

  const path = `${scale}.${token}`
  return memoizedGet(theme, path, fallback ?? token)
}

export { useBreakpointValue, useMediaQuery }

/**
 * In some cases it doesn't make sense to specify the
 * server-side behaviour in terms of a default breakpoint -
 * instead you want a specific value to be used on the
 * server-side regardless of the values at different breakpoints
 */
export function useBreakpointValueClientOnly<T = any>(
  values: Partial<Record<string, T>> | Array<T | null>,
  ssrValue: T
): T {
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    setIsMounted(true)
  }, [])
  const breakpointValue = useBreakpointValue(values) ?? ssrValue
  return isMounted ? breakpointValue : ssrValue
}

type Scalar = number | string | boolean

/*
If you know how to calculate a value for a Hub property that's
some type (a string, number or boolean) based on some other Hub
property that's some other type (ditto), you can use mapResponsiveValue
to generalise that calculation to a HubResponsiveValue and get a
HubResponsiveValue back.
*/

export function mapResponsiveValue<From extends Scalar, To extends Scalar>(
  responsiveValue: HubResponsiveValue<From> | undefined,
  scalarTransform: (from: From | undefined | null) => To | undefined | null
): HubResponsiveValue<To> | undefined {
  if (Array.isArray(responsiveValue)) {
    return responsiveValue.map(value => scalarTransform(value) ?? null)
  } else if (typeof responsiveValue === 'object') {
    return mapValues(
      responsiveValue,
      value => scalarTransform(value) ?? undefined
    )
  } else {
    return scalarTransform(responsiveValue) ?? undefined
  }
}
