import { ApolloClient } from '@apollo/client'
import { FeatureRuleSet } from '@scentregroup/feature-management'
import {
  GetFeatureManagementCollectionDocument,
  GetFeatureManagementCollectionQuery,
  GetFeatureManagementCollectionV2Document,
  GetFeatureManagementCollectionV2Query,
} from '../graphql'

export async function getFeatureMangement<TCacheShape>(
  apollo: Pick<ApolloClient<TCacheShape>, 'query'>,
  scope: string,
  featureSlug: string
): Promise<
  | Exclude<
      GetFeatureManagementCollectionQuery['cfFeatureManagementCollection'],
      null | undefined
    >['items']
  | null
> {
  const { data } = await apollo.query({
    context: { version: 2 },
    query: GetFeatureManagementCollectionDocument,
    variables: {
      scope,
      featureSlug,
    },
  })

  if (!data?.cfFeatureManagementCollection?.items) {
    return null
  }
  return data?.cfFeatureManagementCollection?.items
}

export async function getFeatureManagementV2<TCacheShape>(
  apollo: Pick<ApolloClient<TCacheShape>, 'query'>,
  scope: string
): Promise<FeatureRuleSet[]> {
  const { data } = await apollo.query({
    context: { version: 2 },
    query: GetFeatureManagementCollectionV2Document,
    variables: {
      scope,
    },
  })

  return queriedFeatureManagementCollectionToFeatureRuleSet(
    data.featureManagementFeatures
  )
}

function queriedFeatureManagementCollectionToFeatureRuleSet(
  queriedCollection: GetFeatureManagementCollectionV2Query['featureManagementFeatures']
): FeatureRuleSet[] {
  return queriedCollection.flatMap(record =>
    record
      ? {
          featureSlug: record.featureSlug,
          type: record.type,
          defaultResult: record.defaultResult,
          rules: record.rules.flatMap(recordRule =>
            recordRule
              ? {
                  condition: recordRule.condition,
                  results: recordRule.results.flatMap(recordRuleResult =>
                    recordRuleResult
                      ? {
                          cohortName: recordRuleResult.cohortName ?? '',
                          distribution: recordRuleResult.distribution,
                          result: recordRuleResult.result,
                        }
                      : []
                  ),
                }
              : []
          ),
        }
      : []
  )
}
