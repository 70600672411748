import React, { useEffect, useState } from 'react'
import type { FC, PropsWithChildren, ComponentType } from 'react'

const ClientOnly: FC<PropsWithChildren<unknown>> = function ClientOnly({
  children,
}) {
  const [hasBeenMounted, setHasBeenMounted] = useState(false)

  useEffect(() => {
    setHasBeenMounted(true)
  }, [])

  if (!hasBeenMounted) {
    return <></>
  }

  return <>{children}</>
}

export default ClientOnly

export function withClientOnly(
  Component: ComponentType<PropsWithChildren<unknown>>
): ComponentType<PropsWithChildren<unknown>> {
  const ClientOnlyWrapped: FC<PropsWithChildren<unknown>> = () => (
    <ClientOnly>
      <Component />
    </ClientOnly>
  )

  const wrappedName = Component.displayName ?? Component.name ?? Component.name
  ClientOnlyWrapped.displayName = `WithClientOnly(${wrappedName})`

  return ClientOnlyWrapped
}
