import React, { createContext, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { SessionIdStorage } from './storage'

export interface SessionIdContextValue {
  generateSessionId(): string
  getOrGenerateSessionId(): string
}

export const SessionIdContext = createContext<
  SessionIdContextValue | undefined
>(undefined)
SessionIdContext.displayName = 'SessionIdContext'

export type SessionIdGenerator = () => string

export function defaultSessionIdGenerator(): string {
  return uuidv4()
}

export const SessionIdProvider: React.FC<
  React.PropsWithChildren<{
    generator?: SessionIdGenerator
    storage: SessionIdStorage
  }>
> = ({ generator = defaultSessionIdGenerator, storage, children }) => {
  const provider: SessionIdContextValue = useMemo(() => {
    const generateSessionId = (): string => {
      const sessionId = generator()
      storage.setSessionId(sessionId)
      return sessionId
    }

    const getOrGenerateSessionId = (): string => {
      const storedSessionId = storage.getSessionId({ extendSession: true })
      if (storedSessionId) {
        return storedSessionId
      }
      return generateSessionId()
    }

    getOrGenerateSessionId()

    return {
      generateSessionId,
      getOrGenerateSessionId,
    }
  }, [generator, storage])

  return (
    <SessionIdContext.Provider value={provider}>
      {children}
    </SessionIdContext.Provider>
  )
}
