import React, { ComponentProps } from 'react'
import { ModalFooter as ChakraModalFooter } from '@chakra-ui/react'

import { forwardRef } from '@hub/design-system-base'

type ChakraModalFooterProps = ComponentProps<typeof ChakraModalFooter>

type ModalFooterProps = Exclude<ChakraModalFooterProps, 'ref'>

export const ModalFooter = forwardRef<
  ModalFooterProps,
  typeof ChakraModalFooter
>((props, ref) => {
  return <ChakraModalFooter ref={ref} {...props} />
})
