import { injectTheme } from '@hub/design-system-base'
import { modalTheme } from './theme'
import { ModalBody } from './body'
import { ModalCloseButton } from './close-button'
import { ModalFooter } from './footer'
import { ModalHeader } from './header'
import { ModalOverlay } from './overlay'
import { ModalContent } from './content'
import { Modal, ModalProps } from './modal'

export {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalContent,
}

export type { ModalProps }

injectTheme('Modal', modalTheme)
