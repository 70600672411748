import React, { createContext, useContext, useState } from 'react'
import { Link } from '@hub/link'
import { ButtonProps } from '@hub/button'

export enum MagicLinkValue {
  stores,
  centres,
}

type MagicLinkState = [
  value: null | MagicLinkValue | undefined,
  setValue: (value?: null | MagicLinkValue) => void,
]

const MagicLinkContext = createContext<MagicLinkState>([null, () => {}])

export const MagicLinkProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const value = useState<MagicLinkState[0]>(null)
  return (
    <MagicLinkContext.Provider value={value}>
      {children}
    </MagicLinkContext.Provider>
  )
}

export const useMagicLink = (): MagicLinkState => {
  return useContext(MagicLinkContext)
}

type MagicLinkProps = React.ComponentProps<typeof Link> & {
  buttonVariant?: ButtonProps['variant']
}

const MagicLink: React.FC<React.PropsWithChildren<MagicLinkProps>> = ({
  buttonVariant: _buttonVariant,
  ...linkProps
}) => {
  const [, setValue] = useMagicLink()
  const handleClick = (e: React.MouseEvent): void => {
    linkProps.onClick?.(e)
    e.preventDefault()
    if (linkProps.href === '/stores') {
      setValue(MagicLinkValue.stores)
    }
    if (linkProps.href === '/centres') {
      setValue(MagicLinkValue.centres)
    }
  }
  const { href, ...restLinkProps } = linkProps

  // https://scentregroup.atlassian.net/browse/MKP-7485 for avoiding
  // crawlers will hit 404s as /centres page does not exist,
  // but convert to button approach will cause styling issue as MKP-7988
  // as a workaround we set href as empty
  // TODO: remove this rule once /centres page is ready
  if (href === '/centres') {
    return <Link {...restLinkProps} href={''} onClick={handleClick} />
  }
  return <Link {...linkProps} />
}

export default MagicLink
