import React, { ComponentProps, useContext, useMemo } from 'react'
import { LinkOverlay as ChakraLinkOverlay } from '@chakra-ui/react'
import {
  As,
  HubStyleObject,
  forwardRef,
  HubLinkContext,
} from '@hub/design-system-base'

interface LinkOverlayProps {
  as?: As
  sx?: HubStyleObject
  href: string
  isExternal?: boolean
  onClick?: React.MouseEventHandler
}

// TODO: Make work with Next links, external links, and internal non-Next links
export const LinkOverlay = forwardRef<
  LinkOverlayProps,
  typeof ChakraLinkOverlay
>(({ children, href, isExternal, as, sx, onClick, ...restProps }, ref) => {
  const Link = useContext(HubLinkContext)
  const LinkOverlayAs = useMemo(
    () =>
      as ||
      (Link &&
        ((
          props: Omit<ComponentProps<typeof Link>, 'to'> & { href: string }
        ) => <Link to={props.href} {...props} {...restProps} />)),
    [Link, as, restProps]
  )
  return (
    <ChakraLinkOverlay
      ref={ref}
      href={href}
      as={LinkOverlayAs}
      sx={sx}
      isExternal={isExternal}
      onClick={onClick}
    >
      {children ?? href}
    </ChakraLinkOverlay>
  )
})
