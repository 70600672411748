;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"website-client@45560"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/account";import * as Sentry from '@sentry/nextjs'

import { isSupportedBrowser } from '@scentregroup/shared/helpers/supported-browsers'

declare global {
  interface Navigator {
    userAgentData?: unknown
  }
}

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV
const sentryIsProduction = SENTRY_ENV == 'prd'
const sentryTracesSampleRate = sentryIsProduction ? 0.001 : 0.1

function sendIfSupportedBrowser<
  TToSend extends Sentry.Event | Sentry.Breadcrumb,
>(toSend: TToSend, request: Sentry.Request | undefined): TToSend | null {
  // Detect user agent client hints - only very new browsers have this
  const { userAgentData } = navigator
  if (typeof userAgentData === 'object' && userAgentData !== null) {
    return toSend
  }

  return isSupportedBrowser(request?.headers?.['User-Agent']) ? toSend : null
}

Sentry.init({
  dsn: SENTRY_DSN,
  environment: SENTRY_ENV,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/api\.([^.]+\.)*scentregroup\.io\//,
    /^\//,
  ],
  tracesSampleRate: sentryTracesSampleRate,
  // List of integrations included by default
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/integrations/#browser-integrations
  integrations: [Sentry.extraErrorDataIntegration()],
  beforeSend: (event: Sentry.ErrorEvent) => {
    return sendIfSupportedBrowser(event, event?.request)
  },
  beforeBreadcrumb: (
    breadcrumb: Sentry.Breadcrumb,
    hint?: Sentry.BreadcrumbHint | undefined
  ) => {
    return sendIfSupportedBrowser(breadcrumb, hint?.event?.request)
  },
})

Sentry.setTag('app', 'account')
