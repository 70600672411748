export const STATUS_OK = 200
export const STATUS_NOT_FOUND = 404
export const STATUS_SERVER_ERROR = 500
export const MIN_DAYS_TO_SHOW = 7
export const MIN_MOVIES_TO_SHOW = 4
export const MIN_MOVIES_TO_SHOW_V2 = 5
export const MOVIE_DAYS_IN_ADVANCE = 7
export const OPEN_LATE_HOUR = 19
export const AFTERPAY_TEXT = `Accepts Afterpay`
export const GIFT_CARD_TEXT = `Accepts Westfield Gift Cards`
export const GIFT_CARD_MENU_TEXT = `gift cards`
export const STORE_HOURS_DIFFER = `(store hours may differ)`
export const HOURS_DINELATE = `Shop and dine till late`
export const HOURS_BANNER_DINELATE = `shop and dine till late`
export const CENTRE_HOURS_TEXT_LATE = `Many stores operate outside the hours below. Enjoy late night dining and entertainment, convenient supermarket shopping, and more.`
export const CENTRE_HOURS_TEXT = `Major stores may operate outside the hours below, for convenient supermarket shopping, and more.`
export const SEE_MORE_OPENING_HOURS_TEXT = `See more opening hours`
export const SEE_MORE_MOVIES_TEXT = `See all Movies`
export const SEE_MORE_TEXT = `See all`
export const GRAPHQL_V2_VERSION_NUMBER = 2

export const MARKETPLACE_PRODUCTS_INDEX_DEFAULT_AU = 'products_au_updated_desc'
export const MARKETPLACE_PRODUCTS_INDEX_DEFAULT_NZ = 'products_nz_updated_desc'

export const HIDE_UTILITY_BOX = ['Event']
export const ACCORDION_ORDER = [['occurrences'], ['location', 'priceInfo']]
export const AVAILABLE_CALENDARS = ['iCal']
export const DEFAULT_CALENDAR = 'iCal'

export const WHATS_ON_EVENT_SAME_DAY = 'ddd DD MMM YYYY h:mma'
export const WHATS_ON_EVENT_NEXT_DAY = 'ddd DD MMM YYYY h:mma'
export const WHATS_ON_EVENT_NEXT_WEEK = 'ddd DD MMM YYYY h:mma'
export const WHATS_ON_EVENT_SAME_ELSE = 'ddd DD MMM YYYY h:mma'
export const DISCOVER = 'Discover'

// OPENGRAPH META FALLBACK VALUES
export const META_FALLBACK_IMAGE = `https://images.scentregroup.io/image/upload/v1523242874/westfield-logo.inline_xhroop.svg`
export const META_FALLBACK_DESCRIPTION = `No description provided.`

export enum ViewOption {
  MEMBERS_ONLY = 'Members only',
  HIDE_FROM_MEMBERS = 'Hide from Members',
  EVERYONE = 'Everyone',
}

export const WESTFIELD_PLUS_URL = '/westfield-plus'

export const CHINA_WESTFIELD_URLS = [
  'china.westfield.co.nz',
  'china.westfield.com.au',
]

export const trustedHostnameSuffixes = [
  'scentregroup.com',
  'scentregroup.io',
  'scentregroup.cloud',
  'westfield.com.au',
  'westfield.co.nz',
  'westfieldgiftcards.com.au',
]

export const trackingActions = {
  viewPromotion: 'ga.event.viewPromotion',
}

export const trackingTypes = {
  promotion: 'data-promotion',
}

export const trackingNullValue = '/gtm/null'

export enum RawCookieName {
  SESSION_ID = '_sg_sessionId',
  TRACKED_TRANSACTION = '_sg_trackedTransaction',
  DGC_CART_ID = '_sg_dgc_cartId',
  DGC_CART_PREVIOUS_ID = '_sg_dgc_cartPreviousId',
  DGC_CART_COUNT = '_sg_dgc_cartCount',
  CART_ID = '_sg_cartId',
  CART_PREVIOUS_ID = '_sg_cartPreviousId',
  CART_COUNT = '_sg_cartCount',
  SUBURB = '_sg_suburb',
  CENTRE = '_sg_centre',
  ITEMS_PER_PAGE = '_sg_itemsPerPage',
  CREDIT_CARD_CHECKBOX = 'creditCardCheckbox',
  DELIVERY_ADDRESS_CHECKBOX = 'deliveryAddressCheckbox',
  ID_TOKEN = 'idToken',
  SCENTREGROUP_ID = '_sg_scentregroup_id', // required for google analytics
  FEATURE_FLAGS = '_sg_feature_flags',
  REMOTE_FEATURE_MANAGEMENT = '_sg_remote_feature_management',
  GOOGLE_ANALYTICS = '_ga',
  USER_FLOW = 'universal-login-flow',
  ONBOARDING = '_sg_onboarding',
  ONE_TIME_PAYMENT_REFERENCE = '_sg_oneTimePaymentReference',
}

export const REVALIDATION_PERIODS = {
  static: 1 * 60,
  dynamic: 1 * 60,
  notFound: 1 * 60,
  productDetails: 5 * 60,
}

export const FULFILMENT_DAYS = {
  CLICK_AND_COLLECT: { MIN: 3, MAX: 6 },
  DELIVERY: { MIN: 3, MAX: 12 },
}

export enum CustomWindowEvents {
  REMOTE_FEATURE_MANAGEMENT_INVALIDATE_CACHE = '_sg_remote_feature_management_invalidate_cache',
}

export const MIN_LIVE_SEARCH_QUERY_LENGTH = 1

export const universalHeaderId = 'universal-header'

export const HTTP_STATUS = {
  SUCCESS: 200,
  NOT_FOUND: 404,
}
