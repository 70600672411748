import { stringify } from 'query-string'

import { useRouter } from '../router'

interface Location {
  search: string
  pathname: string
}

function useLocation(): Location {
  const nextRouter = useRouter()

  const queryString = stringify(nextRouter.query)

  return {
    search: queryString ? `?${queryString}` : '',
    pathname: nextRouter.asPath,
  }
}

export { useLocation }
