import url from 'url'
import React from 'react'
import NextLink from 'next/link'

import { Link as LinkType } from '../types/link'
import { useRouter } from '../next/router'
import { hrefIsOutsideRouter } from './router'
import { pathRemovingBasePath } from '@scentregroup/shared/lib/path'
import { useGetHrefContext } from '@scentregroup/shared/context'

const isOutbound = (target: string, current: string): boolean => {
  const currentUrl = url.parse(current, false)
  const targetUrl = url.parse(target, false, true)
  return Boolean(
    targetUrl.host &&
      (targetUrl.protocol !== currentUrl.protocol ||
        targetUrl.host !== currentUrl.host ||
        targetUrl.port !== currentUrl.port)
  )
}

const OutboundWrapper: React.FC<
  React.PropsWithChildren<{
    href: string
    isAbsolute: boolean
    rel?: string
  }>
> = ({ href, isAbsolute, children, rel = '', ...props }) => {
  const getHref = useGetHrefContext()

  const relParts = rel.split(' ').filter(Boolean)

  // Flag this link as `rel="external"` while also retaining any other `rel=""`
  // attribtues the component might have
  if (
    isAbsolute &&
    isOutbound(href, getHref()) &&
    !relParts.includes('external')
  ) {
    relParts.unshift('external')
  }

  return (
    <a
      href={href}
      {...(relParts.length && { rel: relParts.join(' ') })}
      {...props}
    >
      {children}
    </a>
  )
}

export const Link: LinkType = ({ to, children, ...props }) => {
  const router = useRouter()

  // coerce (string | object) to string
  const href = url.format(to)

  // A route with a schema was passed.  We assume it's an external route so we
  // skip wrapping in the <Link> component
  const isAbsolute = /^[a-zA-Z]+:/.test(href)

  // These links are not handled by our router, we need to trigger a full-page navigation
  const isOutsideRouter = hrefIsOutsideRouter(
    router.basePath + router.pathname,
    href
  )

  if (isAbsolute || isOutsideRouter) {
    return (
      <OutboundWrapper {...props} isAbsolute={isAbsolute} href={href}>
        {children}
      </OutboundWrapper>
    )
  }

  const hrefWithoutBasePath = pathRemovingBasePath(href, router.basePath)

  return (
    <NextLink {...props} href={hrefWithoutBasePath}>
      {children}
    </NextLink>
  )
}
