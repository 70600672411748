import React, {
  useCallback,
  useMemo,
  useState,
  createContext,
  ReactNode,
  useContext,
} from 'react'

import { INotification, NotificationType } from '../types/notification'
export { NotificationType }

type NotificationContextProviderValue = {
  closeNotification: () => void
  notification: INotification | null
  openNotification: (values: INotification) => void
}

export const NotificationContext = createContext(
  {} as NotificationContextProviderValue
)

type Props = {
  children: ReactNode
}

export const NotificationProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const [notification, setNotification] = useState<INotification | null>(null)

  const openNotification = useCallback(
    ({ message, type, title, link }: INotification) =>
      setNotification({ message, type, title, link }),
    []
  )

  const closeNotification = useCallback(() => setNotification(null), [])

  const providerValue = useMemo(
    () => ({ openNotification, closeNotification, notification }),
    [openNotification, closeNotification, notification]
  )

  return (
    <NotificationContext.Provider value={providerValue}>
      {children}
    </NotificationContext.Provider>
  )
}

export default (): NotificationContextProviderValue =>
  useContext(NotificationContext)
