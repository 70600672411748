import { useEffect, useState } from 'react'
import { pick, sortBy } from 'lodash'

const useMemoizedPick = <T extends Record<string, any>>(
  obj: T,
  keys: Readonly<(keyof T)[]>
): Pick<T, (typeof keys)[number]> => {
  const [picked, setPicked] = useState(pick(obj, sortBy(keys)))
  const newPicked = pick(obj, sortBy(keys))
  useEffect(() => {
    setPicked(newPicked)
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(newPicked))
  return picked
}

export default useMemoizedPick
