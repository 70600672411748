import { makeCountrySpecificValue } from '@scentregroup/shared/configuration'
import { RawCookieName } from '@scentregroup/shared/constants'

import ISharedEnv, { ISharedEnvInput } from '../../../../types/shared-env'
import { stackEnvToEnvironment, toNumber } from '../../server/lib/env'
import { sharedEnvInput } from './shared-env-input'

export function makeSharedEnv(input: ISharedEnvInput): ISharedEnv {
  const nodeEnvironment = input.NODE_ENV ?? 'development'
  const environment = stackEnvToEnvironment(input.STACK_ENV)

  return {
    graphqlApi: {
      v1: input.GRAPHQL_API_V1 ?? 'http://localhost:5000/v1/graphql',
      v2: input.GRAPHQL_API_V2 ?? 'http://localhost:5000/v2/graphql',
    },
    basePath: '/shop',
    siteMapHostName: input.SITEMAP_HOSTNAME ?? 'https://www.westfield.com.au',

    isDeployed: !!environment,

    isDevelopment: nodeEnvironment === 'development',

    sharedCookiesDomain: input.SHARED_COOKIES_DOMAIN,
    cookieTrackedTransaction: RawCookieName.TRACKED_TRANSACTION,
    cookieDGCCartName: RawCookieName.DGC_CART_ID,
    cookieDGCCartPreviousName: RawCookieName.DGC_CART_PREVIOUS_ID,
    cookieDGCCartCountName: RawCookieName.DGC_CART_COUNT,
    cookieCartName: RawCookieName.CART_ID,
    cookieCartPreviousName: RawCookieName.CART_PREVIOUS_ID,
    cookieCartCountName: RawCookieName.CART_COUNT,
    cookieSuburbName: RawCookieName.SUBURB,
    cookieCentreName: RawCookieName.CENTRE,
    cookieIdTokenName: RawCookieName.ID_TOKEN,
    cookieScentregroupId: RawCookieName.SCENTREGROUP_ID,
    cookieItemsPerPage: RawCookieName.ITEMS_PER_PAGE,
    cookieCreditCardCheckbox: RawCookieName.CREDIT_CARD_CHECKBOX,
    cookieDeliveryAddressCheckbox: RawCookieName.DELIVERY_ADDRESS_CHECKBOX,
    cookieUserFlow: RawCookieName.USER_FLOW,
    cookieMaxAgeInSeconds: toNumber(input.COOKIE_MAX_AGE_IN_SECONDS) ?? 604800, // 1 week
    cookieOnboarding: RawCookieName.ONBOARDING,
    cookieOneTimePaymentReference: RawCookieName.ONE_TIME_PAYMENT_REFERENCE,

    contactEmailAddress:
      input.CONTACT_EMAIL_ADDRESS ?? 'giftcards@scentregroup.com',

    googleTagManager: {
      containerId: input.GTM_ID,
      previewEnvironment: input.GTM_PREVIEW_ENV,
      previewAuth: input.GTM_PREVIEW_AUTH,
      trackingTimeout: toNumber(input.GTM_TRACKING_TIMEOUT) ?? 2500,
    },
    stripe: {
      publishableKey: input.STRIPE_PUBLISHABLE_KEY!,
    },
    googleReCaptcha: {
      siteKey: input.RECAPTCHA_SITE_KEY,
    },
    sentry: {
      dsn: input.SENTRY_DSN,
      release: input.SENTRY_RELEASE,
      environment: input.SENTRY_ENV,
      clientKey: input.SENTRY_CLIENT_KEY,
      securityHeaderReportUri: input.SENTRY_SECURITY_HEADER_REPORT_URI,
    },
    urls: {
      privacyPolicy: makeCountrySpecificValue(
        input.PRIVACY_POLICY_URL_AU,
        input.PRIVACY_POLICY_URL_NZ
      ),
      termsAndConditions:
        input.TERMS_AND_CONDITIONS_URL ??
        'https://westfield.com.au/terms-and-conditions#gift-cards-online-purchase-terms-and-conditions',
      contactUs:
        input.CONTACT_US ??
        'https://support.westfield.com.au/hc/en-us/requests/new',
      faq:
        input.FAQ ??
        'https://support.westfield.com.au/hc/en-us/sections/360007624151-Digital-Gift-Cards',
      accountSupport:
        input.ACCOUNT_SUPPORT_URL ??
        'https://support.westfield.com.au/hc/en-us/articles/4408415688973-How-do-I-update-my-account-details-',
      giftCardTermsAndConditions:
        input.GIFT_CARD_TERMS_AND_CONDITIONS_URL ??
        'https://westfield.com.au/terms-and-conditions#digital-gift-card-product-terms-and-conditions', // Footer t&c
      directReturnPolicy:
        input.DIRECT_RETURN_POLICY ??
        'https://support.westfield.com.au/hc/en-us/articles/360057585852',
    },
    algolia: {
      contentApplicationId: input.ALGOLIA_CONTENT_APPLICATION_ID!,

      contentApiKey: input.ALGOLIA_CONTENT_API_KEY!,
    },
    auth0: {
      domain: input.AUTH0_DOMAIN ?? 'auth.dev.scentregroup.io',
      clientId: input.AUTH0_CLIENT_ID ?? 'xbAyEx_3Nbkef_d5tlQ8g6kumhqmWA_a',
    },
    enableApplePay: input.ENABLE_APPLE_PAY === 'true',
  }
}

export const staticSharedEnv = makeSharedEnv(sharedEnvInput)
