import React, { ComponentProps } from 'react'
import { ModalCloseButton as ChakraModalCloseButton } from '@chakra-ui/react'

import { forwardRef } from '@hub/design-system-base'

type ChakraModalCloseButtonProps = ComponentProps<typeof ChakraModalCloseButton>

type ModalCloseButtonProps = Exclude<ChakraModalCloseButtonProps, 'ref'>

export const ModalCloseButton = forwardRef<
  ModalCloseButtonProps,
  typeof ChakraModalCloseButton
>((props, ref) => {
  return (
    <ChakraModalCloseButton
      sx={{
        position: 'absolute',
        right: 'spacing-lg',
        top: 'spacing-md',
        height: 'size-6',
        width: 'size-6',
        fontSize: 'font-tiny',
      }}
      ref={ref}
      {...props}
    />
  )
})
