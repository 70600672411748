import React from 'react'
import { Modal as ChakraModal } from '@chakra-ui/react'

import { forwardRef, HubStyleObject } from '@hub/design-system-base'
import { Box } from '@hub/box'

export type ModalProps = {
  isOpen: boolean
  heading?: string
  action?: {
    label: string
    onClick: () => void
  }
  onClose: () => void
  children: React.ReactNode
  closeOnOverlayClick?: boolean
  dataTestId?: string
  className?: string
  isCentered?: boolean
  sx?: HubStyleObject
}

export const Modal = forwardRef<ModalProps, typeof ChakraModal>(
  (props, ref) => {
    return (
      <ChakraModal {...props}>
        <Box ref={ref}>{props.children}</Box>
      </ChakraModal>
    )
  }
)
