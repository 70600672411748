import React from 'react'
import { Text as ChakraText } from '@chakra-ui/react'
import {
  As,
  HubStyleObject,
  StandardTextAlignment,
  StandardColors,
  StandardFontSizes,
  StandardLineHeights,
  HubResponsiveValue,
  forwardRef,
} from '@hub/design-system-base'

export interface TextProps {
  as?: As
  tone?: 'neutral' | 'strong' | 'emphasized'
  fontSize?: HubResponsiveValue<StandardFontSizes>
  sx?: HubStyleObject
  lineHeight?: HubResponsiveValue<StandardLineHeights>
  className?: string
  color?: StandardColors
  align?: StandardTextAlignment
  decoration?: 'underline' | 'none'
  noOfLines?: HubResponsiveValue<number>
  isBodyText?: boolean
  id?: string
  for?: string
}

export const Text = forwardRef<TextProps, typeof ChakraText>(
  (
    {
      as,
      className,
      fontSize,
      tone = 'neutral',
      sx,
      children,
      lineHeight,
      color = 'textPrimary',
      decoration = 'none',
      align,
      noOfLines,
      isBodyText = false,
      id,
    },
    ref
  ) => (
    <ChakraText
      ref={ref}
      as={as}
      className={className}
      fontSize={fontSize}
      lineHeight={lineHeight || (isBodyText ? 'base' : 'shorter')}
      color={color}
      align={align}
      decoration={decoration}
      noOfLines={noOfLines}
      id={id}
      sx={{
        whiteSpace: 'initial',
        ...(tone === 'strong' && { fontWeight: 'bold' }),
        ...(tone === 'emphasized' && { fontStyle: 'italic' }),
        ...sx,
      }}
    >
      {children}
    </ChakraText>
  )
)

Text.displayName = 'Text'
