import React, { ComponentProps } from 'react'
import { ModalOverlay as ChakraModalOverlay } from '@chakra-ui/react'

import { forwardRef } from '@hub/design-system-base'

type ChakraModalOverlayProps = ComponentProps<typeof ChakraModalOverlay>

type ModalOverlayProps = Exclude<ChakraModalOverlayProps, 'ref'>

export const ModalOverlay = forwardRef<
  ModalOverlayProps,
  typeof ChakraModalOverlay
>((props, ref) => {
  return <ChakraModalOverlay ref={ref} {...props} />
})
