import { modalAnatomy } from '@chakra-ui/anatomy'

const baseStyleOverlay = {
  background: 'rgba(22, 23, 25, 0.16)', // licorice 16%
}

const baseStyleHeader = {
  fontSize: 'font-xl',
  lineHeight: 'shorter',
  marginBottom: 'spacing-md',
  paddingLeft: 'spacing-none',
}

const baseStyleBody = {
  fontSize: 'font-sm',
  marginTop: 'spacing-sm',
  marginBottom: 'spacing-xl',
  paddingLeft: 'spacing-none',
  paddingRight: 'spacing-none',
}

const baseStyleFooter = {
  padding: 'spacing-none',
}

const baseStyle = (
  _props: Record<string, any>
): {
  overlay: typeof baseStyleOverlay
  header: typeof baseStyleHeader
  body: typeof baseStyleBody
  footer: typeof baseStyleFooter
} => ({
  overlay: baseStyleOverlay,
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
})

export const modalTheme = {
  modalAnatomy,
  baseStyle,
}
