import { ApolloLink } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

function isCookieEnabledBrowser(): boolean {
  return typeof navigator === 'object' && navigator.cookieEnabled === true
}

interface DefaultHeadersLinkOptions {
  requestId?: string
  getSessionId?: () => string
  getAnonymousId?: () => string | null | undefined
}

export function makeDefaultHeadersLink({
  requestId,
  getSessionId,
  getAnonymousId,
}: DefaultHeadersLinkOptions = {}): ApolloLink {
  return new ApolloLink((operation, forward) => {
    operation.setContext(
      ({
        version,
        headers,
      }: {
        version: number | undefined
        headers: Record<string, any>
      }) => {
        const defaultHeaders: Record<string, any> = {
          'x-request-id': requestId || uuidv4(),
        }

        if (getSessionId && version === 2) {
          const sessionId = getSessionId()
          if (sessionId) {
            defaultHeaders['X-Scg-Session-Id'] = sessionId
          }
        }

        if (getAnonymousId && version === 2) {
          const anonymousId = getAnonymousId()
          if (anonymousId) {
            defaultHeaders['X-Segment-Anonymous-Id'] = anonymousId
          }
        }

        if (isCookieEnabledBrowser()) {
          const tabId = window.sessionStorage.getItem('tabId') as string
          if (tabId) {
            defaultHeaders['X-Tab-Session-Id'] = tabId
          }
        }

        return {
          headers: {
            ...headers,
            ...defaultHeaders,
          },
        }
      }
    )

    return forward(operation)
  })
}
