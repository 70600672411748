import React, { createContext } from 'react'

export type Link = React.FC<
  React.PropsWithRef<
    React.PropsWithChildren<{
      to: string | { pathname: string; search?: string }
    }>
  >
>

export const HubLinkContext = createContext<Link | undefined>(undefined)
