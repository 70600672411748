import { getEnvBool } from './config/get-env'
import { FeatureFlagName } from './feature-flags'
import { arrayToObject } from './helpers'

// WARNING! Don't put anything here that you do not want to be exposed to the client.
export const CONFIG = [
  'NEXT_PUBLIC_ASSET_HOST',
  'NEXT_PUBLIC_AU_HOST',
  'NEXT_PUBLIC_NZ_HOST',
  'NEXT_PUBLIC_CONTENT_SERVICE',
  'NEXT_PUBLIC_CONTENT_SERVICE_V2',
  'NEXT_PUBLIC_ALGOLIA_APPLICATION_ID',
  'NEXT_PUBLIC_ALGOLIA_SEARCH_KEY',
  'NEXT_PUBLIC_MKP_ALGOLIA_APPLICATION_ID',
  'NEXT_PUBLIC_MKP_ALGOLIA_SEARCH_KEY',
  'NEXT_PUBLIC_SENTRY_DSN',
  'NEXT_PUBLIC_SENTRY_ENV',
  'NEXT_PUBLIC_AUTH0_DOMAIN',
  'NEXT_PUBLIC_AUTH0_CLIENT_ID',
  'NEXT_PUBLIC_GTM_ID',
  'NEXT_PUBLIC_GTM_PREVIEW_ENV',
  'NEXT_PUBLIC_GTM_PREVIEW_AUTH',
  'NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE',
  ...Object.keys(FeatureFlagName),
] as const

export const CONFIG_KEYS = arrayToObject(CONFIG)

export const getClientConfigValue = (configKey: string): string | undefined => {
  // Otherwise, if on the server (either Next.js or existing site), or in the browser, but without
  // window.__ENV__ defined (ie; in the browser with Next.js), then we return the value of process.env
  // We need a map of all the keys to their process.env values manually because Next JS
  // does a search and replace like operation to populate these values in the client side bundle.

  return {
    NEXT_PUBLIC_ASSET_HOST: process.env.NEXT_PUBLIC_ASSET_HOST,
    NEXT_PUBLIC_AU_HOST: process.env.NEXT_PUBLIC_AU_HOST,
    NEXT_PUBLIC_NZ_HOST: process.env.NEXT_PUBLIC_NZ_HOST,
    NEXT_PUBLIC_CONTENT_SERVICE: process.env.NEXT_PUBLIC_CONTENT_SERVICE,
    NEXT_PUBLIC_CONTENT_SERVICE_V2: process.env.NEXT_PUBLIC_CONTENT_SERVICE_V2,
    NEXT_PUBLIC_ALGOLIA_APPLICATION_ID:
      process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
    NEXT_PUBLIC_ALGOLIA_SEARCH_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
    NEXT_PUBLIC_MKP_ALGOLIA_APPLICATION_ID:
      process.env.NEXT_PUBLIC_MKP_ALGOLIA_APPLICATION_ID,
    NEXT_PUBLIC_MKP_ALGOLIA_SEARCH_KEY:
      process.env.NEXT_PUBLIC_MKP_ALGOLIA_SEARCH_KEY,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENV: process.env.NEXT_PUBLIC_SENTRY_ENV,
    NEXT_PUBLIC_FEAT_FLAG_ANALYTICS_CONSOLE:
      process.env.NEXT_PUBLIC_FEAT_FLAG_ANALYTICS_CONSOLE,
    NEXT_PUBLIC_AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    NEXT_PUBLIC_AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
    NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
    NEXT_PUBLIC_GTM_PREVIEW_ENV: process.env.NEXT_PUBLIC_GTM_PREVIEW_ENV,
    NEXT_PUBLIC_GTM_PREVIEW_AUTH: process.env.NEXT_PUBLIC_GTM_PREVIEW_AUTH,
    NEXT_PUBLIC_FEAT_FLAG_CONSENT_TARGETED_ADS:
      process.env.NEXT_PUBLIC_FEAT_FLAG_CONSENT_TARGETED_ADS,
    NEXT_PUBLIC_FEAT_FLAG_SESSION_LOGGER:
      process.env.NEXT_PUBLIC_FEAT_FLAG_SESSION_LOGGER,
    NEXT_PUBLIC_FEAT_FLAG_ENABLE_CENTRE_STORES_TYPESENSE:
      process.env.NEXT_PUBLIC_FEAT_FLAG_ENABLE_CENTRE_STORES_TYPESENSE,
    NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH:
      process.env.NEXT_PUBLIC_FEAT_FLAG_DISABLE_LIVE_SEARCH,
    NEXT_PUBLIC_FEAT_FLAG_OLYMPIC_COUNTDOWN_OVERRIDE:
      process.env.NEXT_PUBLIC_FEAT_FLAG_OLYMPIC_COUNTDOWN_OVERRIDE,
    NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE:
      process.env.NEXT_PUBLIC_FEAT_FLAG_PREFERRED_CENTRE,
    NEXT_PUBLIC_FEAT_FLAG_MEMBERSHIP_FLEXIBLE_PAGE:
      process.env.NEXT_PUBLIC_FEAT_FLAG_MEMBERSHIP_FLEXIBLE_PAGE,
  }[configKey]
}

export { getEnvBool }

export const getClientConfigBool = (configKey: string): boolean =>
  getEnvBool(getClientConfigValue(configKey))

export const getAlgoliaApiKeys = (): {
  contentApiKey?: string
  contentApplicationId?: string
} => ({
  contentApiKey: getClientConfigValue(
    CONFIG_KEYS.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
  ),
  contentApplicationId: getClientConfigValue(
    CONFIG_KEYS.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID
  ),
})

export type SegmentConfiguration = {
  cdnURL: string | null
  writeKey: string | null
  writeKeyOverridable: boolean
}

export const CONFIG_PROCESS_ENV_MAP = CONFIG.reduce(
  (acc: Record<string, unknown>, key: string) => {
    acc[key] = process.env[key]
    return acc
  },
  {}
)

// In dev mode, we use http. Everywhere else, we use https

const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https'

export const NATIONAL_CONFIG = {
  au: {
    title: 'Westfield Australia',
    meta: [
      {
        name: 'description',
        content:
          "Find thousands of products from your favourite retailers across women's and men's fashion, kids, home and decor and more.",
      },
      {
        name: 'color-scheme',
        content: 'only light',
      },
    ],
    country: 'Australia',
    lang: 'en-AU',
    url: `${protocol}://${getClientConfigValue(
      CONFIG_KEYS.NEXT_PUBLIC_AU_HOST
    )}`,
  },
  nz: {
    title: 'Westfield New Zealand',
    meta: [
      {
        name: 'description',
        content:
          "Find thousands of products from your favourite retailers across women's and men's fashion, kids, home and decor and more.",
      },
      {
        name: 'color-scheme',
        content: 'only light',
      },
    ],
    country: 'New Zealand',
    lang: 'en-NZ',
    url: `${protocol}://${getClientConfigValue(
      CONFIG_KEYS.NEXT_PUBLIC_NZ_HOST
    )}`,
  },
} as const
