import React from 'react'
import type { ComponentProps } from 'react'
import { ModalHeader as ChakraModalHeader } from '@chakra-ui/react'

import { forwardRef } from '@hub/design-system-base'

type ChakraModalHeaderProps = ComponentProps<typeof ChakraModalHeader>

type ModalHeaderProps = Exclude<ChakraModalHeaderProps, 'ref'>

export const ModalHeader = forwardRef<
  ModalHeaderProps,
  typeof ChakraModalHeader
>((props, ref) => {
  return <ChakraModalHeader ref={ref} {...props} />
})
