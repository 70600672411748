import React from 'react'
import { Box as ChakraBox } from '@chakra-ui/react'
import { As, HubStyleObject, forwardRef } from '@hub/design-system-base'

export interface BoxProps {
  sx?: HubStyleObject
  as?: As
  className?: string
  role?: string
}

export const Box = forwardRef<BoxProps, typeof ChakraBox>(
  ({ sx, as, className, children, ...props }, ref) => (
    <ChakraBox ref={ref} as={as} className={className} sx={sx} {...props}>
      {children}
    </ChakraBox>
  )
)

Box.displayName = 'Box'
